/** @file
 * The store tracks the history of screen reader messages and provides a method to set a new screen reader message
 * For the states from this store to be read, ScreenReaderSpeechNotifications.vue must be imported in the main entry component for pack files.
 */

import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

const useScreenReaderNotificationsStore = defineStore('screenReaderNotifications', () => {
  const screenReaderMessageHistory = ref<string[]>([])
  const latestScreenReaderMessage = computed(() =>
    screenReaderMessageHistory.value.length > 0
      ? screenReaderMessageHistory.value[screenReaderMessageHistory.value.length - 1]
      : '',
  )

  const addScreenReaderMessage = (message: string): void => {
    screenReaderMessageHistory.value.push(message)
  }

  return {
    screenReaderMessageHistory,
    latestScreenReaderMessage,
    addScreenReaderMessage,
  }
})

export { useScreenReaderNotificationsStore }
