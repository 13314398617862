// @file composable for accessibility settings
import { useAccessibilitySettingsStore } from '@@/pinia/accessibility_settings_store'
import type { User, UserCamelCase } from '@@/types'
import { createSharedComposable } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import type { Ref } from 'vue'
import { watch } from 'vue'

export const useAccessibilitySettings = createSharedComposable(
  (user?: Ref<User | UserCamelCase | null | undefined>) => {
    const accessibilitySettingsStore = useAccessibilitySettingsStore()
    const stateRefs = storeToRefs(accessibilitySettingsStore)
    const {
      toggleIsKeyboardShortcutsEnabled,
      toggleIsAppMessagesAutoDismissed,
      updateHighContrastModePreference,
      updateReducedMotionPreference,
      init,
      setStateUpdatesToBeLocallySaved,
    } = accessibilitySettingsStore

    watch(
      () => user?.value,
      (updatedUser) => {
        init(updatedUser ?? undefined)
      },
      { immediate: true, deep: true },
    )

    return {
      ...stateRefs,

      toggleIsKeyboardShortcutsEnabled,
      toggleIsAppMessagesAutoDismissed,
      updateHighContrastModePreference,
      updateReducedMotionPreference,
      setStateUpdatesToBeLocallySaved,
    }
  },
)
