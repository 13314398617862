// @file pinia store for accessibility settings
import { trackEvent } from '@@/bits/analytics'
import { captureFetchException } from '@@/bits/error_tracker'
import { __ } from '@@/bits/intl'
import { asciiSafeStringify } from '@@/bits/json_stringify'
import { isRegistered } from '@@/bits/user_model'
import { User as UserApi } from '@@/dashboard/padlet_api'
import { AccessibilitySettingPreference, SnackbarNotificationType } from '@@/enums'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useScreenReaderNotificationsStore } from '@@/pinia/screen_reader_notifications'
import type { UserCamelCase as User, User as UserSnackcase } from '@@/types'
import type { JsonAPIResource } from '@padlet/arvo'
import { useLocalStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

enum UserInfoStatus {
  Loading = 'Loading',
  Completed = 'Completed',
  Errored = 'Errored',
}

export enum AccessibilitySetting {
  KeyboardShortcuts = 'keyboardShortcuts',
  AppMessagesAutoDismiss = 'autoDismissAppMessages',
  HighContrastMode = 'highContrastMode',
  ReducedMotion = 'reducedMotion',
}

export type UserAccessibilitySettings = Pick<
  User,
  'isKeyboardShortcutsEnabled' | 'isAppMessagesAutoDismissed' | 'highContrastModePreference' | 'reducedMotionPreference'
>

interface AwaitingSaveStates {
  isKeyboardShortcuts: boolean
  isAppMessages: boolean
  highContrastMode: boolean
  reducedMotion: boolean
}

interface SettingParams {
  eventName: string
  successMessage: string
  errorMessage: string
  errorSource: string
}

interface BooleanSettingParams extends SettingParams {
  key: 'isKeyboardShortcutsEnabled' | 'isAppMessagesAutoDismissed'
}

interface PreferenceSettingParams extends SettingParams {
  key: 'highContrastModePreference' | 'reducedMotionPreference'
  value: AccessibilitySettingPreference
}

interface LocalAccessibilitySettings {
  isKeyboardShortcutsEnabled: boolean
  isAppMessagesAutoDismissed: boolean
  highContrastModePreference: AccessibilitySettingPreference
  reducedMotionPreference: AccessibilitySettingPreference
}

const DEFAULT_USER_ACCESSIBILITY_SETTINGS: Required<UserAccessibilitySettings> = {
  isKeyboardShortcutsEnabled: true,
  isAppMessagesAutoDismissed: true,
  highContrastModePreference: AccessibilitySettingPreference.System,
  reducedMotionPreference: AccessibilitySettingPreference.System,
}

export const useAccessibilitySettingsStore = defineStore('accessibilitySettings', () => {
  const globalSnackbarStore = useGlobalSnackbarStore()
  const screenReaderNotificationStore = useScreenReaderNotificationsStore()

  const userSettings = ref<UserAccessibilitySettings | null>(null)
  const isKeyboardShortcutsEnabled = ref(DEFAULT_USER_ACCESSIBILITY_SETTINGS.isKeyboardShortcutsEnabled)
  const isAppMessagesAutoDismissed = ref(DEFAULT_USER_ACCESSIBILITY_SETTINGS.isAppMessagesAutoDismissed)
  const highContrastModePreference = ref<AccessibilitySettingPreference>(
    DEFAULT_USER_ACCESSIBILITY_SETTINGS.highContrastModePreference,
  )
  const reducedMotionPreference = ref<AccessibilitySettingPreference>(
    DEFAULT_USER_ACCESSIBILITY_SETTINGS.reducedMotionPreference,
  )

  const userInfoStatus = ref(UserInfoStatus.Loading)
  const isLoadingUserInfo = computed(() => userInfoStatus.value === UserInfoStatus.Loading)

  const isAwaitingSaveIsKeyboardShortcutsEnabledResponse = computed(() => isAwaitingSaveMap.value.isKeyboardShortcuts)
  const isAwaitingSaveIsAppMessagesAutoDismissedResponse = computed(() => isAwaitingSaveMap.value.isAppMessages)
  const isAwaitingSaveHighContrastModePreferenceResponse = computed(() => isAwaitingSaveMap.value.highContrastMode)
  const isAwaitingSaveReducedMotionPreferenceResponse = computed(() => isAwaitingSaveMap.value.reducedMotion)

  const xAccessibilitySettingsModal = ref(false)

  const isAwaitingSaveMap = ref<AwaitingSaveStates>({
    isKeyboardShortcuts: false,
    isAppMessages: false,
    highContrastMode: false,
    reducedMotion: false,
  })

  const stateUpdatesShouldBeLocallySaved = ref(false)

  const localSettings = useLocalStorage<LocalAccessibilitySettings>(
    'accessibility_settings',
    DEFAULT_USER_ACCESSIBILITY_SETTINGS,
  )

  async function updateBooleanSetting({
    key,
    eventName,
    successMessage,
    errorMessage,
    errorSource,
  }: BooleanSettingParams): Promise<void> {
    const stateRef = key === 'isKeyboardShortcutsEnabled' ? isKeyboardShortcutsEnabled : isAppMessagesAutoDismissed
    const loadingKey = key === 'isKeyboardShortcutsEnabled' ? 'isKeyboardShortcuts' : 'isAppMessages'

    stateRef.value = !stateRef.value

    if (stateUpdatesShouldBeLocallySaved.value) {
      localSettings.value[key] = stateRef.value
      screenReaderNotificationStore.addScreenReaderMessage(successMessage)
      return
    }

    try {
      isAwaitingSaveMap.value[loadingKey] = true
      trackEvent(eventName, 'Toggled setting', stateRef.value)

      await UserApi.updateSettingsUserInfo({
        body: asciiSafeStringify({
          data: {
            attributes: { [key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)]: stateRef.value },
          },
        }),
      })

      userSettings.value = {
        ...userSettings.value,
        [key]: stateRef.value,
      }
      screenReaderNotificationStore.addScreenReaderMessage(successMessage)
    } catch (e) {
      captureFetchException(e, { source: errorSource })
      void globalSnackbarStore.setSnackbar({
        message: errorMessage,
        notificationType: SnackbarNotificationType.error,
      })
      stateRef.value = !stateRef.value
    } finally {
      isAwaitingSaveMap.value[loadingKey] = false
    }
  }

  async function updatePreferenceSetting({
    key,
    value,
    eventName,
    successMessage,
    errorMessage,
    errorSource,
  }: PreferenceSettingParams): Promise<void> {
    const stateRef = key === 'highContrastModePreference' ? highContrastModePreference : reducedMotionPreference
    const loadingKey = key === 'highContrastModePreference' ? 'highContrastMode' : 'reducedMotion'
    const previousValue = stateRef.value
    stateRef.value = value

    if (stateUpdatesShouldBeLocallySaved.value) {
      localSettings.value[key] = value
      screenReaderNotificationStore.addScreenReaderMessage(successMessage)
      return
    }

    try {
      isAwaitingSaveMap.value[loadingKey] = true
      trackEvent(eventName, 'Updated setting', value)

      await UserApi.updateSettingsUserInfo({
        body: asciiSafeStringify({
          data: {
            attributes: { [key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)]: value },
          },
        }),
      })

      userSettings.value = {
        ...userSettings.value,
        [key]: value,
      }
      screenReaderNotificationStore.addScreenReaderMessage(successMessage)
    } catch (e) {
      captureFetchException(e, { source: errorSource })
      void globalSnackbarStore.setSnackbar({
        message: errorMessage,
        notificationType: SnackbarNotificationType.error,
      })
      stateRef.value = previousValue
    } finally {
      isAwaitingSaveMap.value[loadingKey] = false
    }
  }

  async function toggleIsKeyboardShortcutsEnabled(): Promise<void> {
    return await updateBooleanSetting({
      key: 'isKeyboardShortcutsEnabled',
      eventName: 'Keyboard shortcuts',
      successMessage: __('Your preference for keyboard shortcuts has been updated.'),
      errorMessage: __('Error updating keyboard shortcuts'),
      errorSource: 'AccessibilitySettingsStoreSaveIsKeyboardShortcutsEnabled',
    })
  }

  async function toggleIsAppMessagesAutoDismissed(): Promise<void> {
    return await updateBooleanSetting({
      key: 'isAppMessagesAutoDismissed',
      eventName: 'Automatically dismiss app messages',
      successMessage: __('Your preference for automatically dismissing app messages has been updated.'),
      errorMessage: __('Error updating setting for automatically dismissing app messages'),
      errorSource: 'AccessibilitySettingsStoreSaveIsAppMessagesAutoDismissed',
    })
  }

  async function updateHighContrastModePreference(value: AccessibilitySettingPreference): Promise<void> {
    return await updatePreferenceSetting({
      key: 'highContrastModePreference',
      value,
      eventName: 'High contrast mode',
      successMessage: __('Your preference for high contrast mode has been updated.'),
      errorMessage: __('Error updating high contrast mode setting'),
      errorSource: 'AccessibilitySettingsStoreSaveHighContrastModePreference',
    })
  }

  async function updateReducedMotionPreference(value: AccessibilitySettingPreference): Promise<void> {
    return await updatePreferenceSetting({
      key: 'reducedMotionPreference',
      value,
      eventName: 'Reduced motion',
      successMessage: __('Your preference for reduced motion has been updated.'),
      errorMessage: __('Error updating reduced motion setting'),
      errorSource: 'AccessibilitySettingsStoreSaveReducedMotionPreference',
    })
  }

  function initializeState(): void {
    if (userSettings.value != null) {
      isKeyboardShortcutsEnabled.value = userSettings.value.isKeyboardShortcutsEnabled ?? true
      isAppMessagesAutoDismissed.value = userSettings.value.isAppMessagesAutoDismissed ?? true
      highContrastModePreference.value =
        userSettings.value.highContrastModePreference ?? AccessibilitySettingPreference.System
      reducedMotionPreference.value =
        userSettings.value.reducedMotionPreference ?? AccessibilitySettingPreference.System
    } else {
      stateUpdatesShouldBeLocallySaved.value = true
      isKeyboardShortcutsEnabled.value = localSettings.value.isKeyboardShortcutsEnabled
      isAppMessagesAutoDismissed.value = localSettings.value.isAppMessagesAutoDismissed
      highContrastModePreference.value = localSettings.value.highContrastModePreference
      reducedMotionPreference.value = localSettings.value.reducedMotionPreference
      userInfoStatus.value = UserInfoStatus.Completed
    }
  }

  async function fetchUserInfo(): Promise<void> {
    try {
      userInfoStatus.value = UserInfoStatus.Loading
      const response = await UserApi.fetchSettingsUserInfo()
      const user = (response.data as JsonAPIResource<User>).attributes
      userSettings.value = user
      userInfoStatus.value = UserInfoStatus.Completed
    } catch (e) {
      if (e instanceof Error && e.message.includes('401')) {
        userInfoStatus.value = UserInfoStatus.Completed
      } else {
        captureFetchException(e, { source: 'AccessibilitySettingsStoreFetchUserInfo' })
        userInfoStatus.value = UserInfoStatus.Errored
      }
    } finally {
      initializeState()
    }
  }

  async function syncLocalSettingsToServer(): Promise<void> {
    try {
      await UserApi.updateSettingsUserInfo({
        body: asciiSafeStringify({
          data: {
            attributes: {
              is_keyboard_shortcuts_enabled: localSettings.value.isKeyboardShortcutsEnabled,
              is_app_messages_auto_dismissed: localSettings.value.isAppMessagesAutoDismissed,
              high_contrast_mode_preference: localSettings.value.highContrastModePreference,
              reduced_motion_preference: localSettings.value.reducedMotionPreference,
            },
          },
        }),
      })

      // Set flag to use server settings going forward
      stateUpdatesShouldBeLocallySaved.value = false
    } catch (e) {
      captureFetchException(e, { source: 'AccessibilitySettingsStoreSyncLocalToServer' })
    }
  }

  const init = (user?: User | UserSnackcase | undefined): void => {
    if (user == null || !isRegistered(user)) {
      initializeState()
      return
    }

    void fetchUserInfo()
  }

  const openAccessibilitySettingsModal = (): void => {
    xAccessibilitySettingsModal.value = true
  }

  const closeAccessibilitySettingsModal = (): void => {
    xAccessibilitySettingsModal.value = false
  }

  const setStateUpdatesToBeLocallySaved = (value: boolean): void => {
    stateUpdatesShouldBeLocallySaved.value = value
  }

  return {
    // Setting states
    userSettings,
    isLoadingUserInfo,
    isKeyboardShortcutsEnabled,
    isAppMessagesAutoDismissed,
    highContrastModePreference,
    reducedMotionPreference,

    // UI state
    xAccessibilitySettingsModal,

    // Loading states
    isAwaitingSaveIsKeyboardShortcutsEnabledResponse,
    isAwaitingSaveIsAppMessagesAutoDismissedResponse,
    isAwaitingSaveHighContrastModePreferenceResponse,
    isAwaitingSaveReducedMotionPreferenceResponse,

    // Actions
    init,
    fetchUserInfo,
    toggleIsKeyboardShortcutsEnabled,
    toggleIsAppMessagesAutoDismissed,
    updateHighContrastModePreference,
    updateReducedMotionPreference,
    openAccessibilitySettingsModal,
    closeAccessibilitySettingsModal,
    setStateUpdatesToBeLocallySaved,
    syncLocalSettingsToServer,
  }
})
