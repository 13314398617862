// @file Global snackbar store
import currentUser from '@@/bits/current_user'
import { isAppUsing } from '@@/bits/flip'
import window from '@@/bits/global'
import { __ } from '@@/bits/intl'
import { SnackbarLoadingStatus, SnackbarNotificationType } from '@@/enums'
import type { SnackbarLoadingState } from '@@/types'
import { useAccessibilitySettings } from '@@/vuecomposables/useAccessibilitySettings'
import { isEqual, uniqueId } from 'es-toolkit/compat'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export interface GlobalSnackbarNotification {
  notificationType?: SnackbarNotificationType
  message: string
  timeout?: number
  persist?: boolean
  dismissable?: boolean
  iconName?: string
  actionText?: string
  actionTextActions?: Function[]
  shouldTruncate?: boolean
  loadingState?: SnackbarLoadingState
}

// Internal type
interface QueuedGlobalSnackbarNotification extends GlobalSnackbarNotification {
  uid: string
  // The snackbar will occupy the originalIndex-th spot on the screen.
  // Since snackbars may be removed, a particular snackbar's position within snackbarArray might change.
  // In order to ensure snackbars don't shift around and don't overlap,
  // we assign a fixed originalIndex when it's originally/first added to the array.
  originalIndex: number
  timeoutId?: number
}

interface SnackbarConfig {
  allowDuplicate?: boolean
}

const DEFAULT_TIMEOUT = 3000

const snackbarNotificationTypePresets: Record<
  SnackbarNotificationType,
  { [P in keyof QueuedGlobalSnackbarNotification]?: QueuedGlobalSnackbarNotification[P] }
> = {
  [SnackbarNotificationType.error]: {
    iconName: 'exclamation',
  },
  [SnackbarNotificationType.success]: {
    iconName: 'checkmark',
  },
  [SnackbarNotificationType.spinner]: {
    loadingState: {
      status: SnackbarLoadingStatus.loading,
      progress: 0,
    },
  },
}

export const useGlobalSnackbarStore = defineStore('globalSnackbar', () => {
  const snackbarArray = ref([] as QueuedGlobalSnackbarNotification[])

  function setSnackbar(newSnackbar: GlobalSnackbarNotification, config?: SnackbarConfig): string {
    const uid = uniqueId('snack_')

    const originalIndex =
      snackbarArray.value.length === 0
        ? 0
        : Math.max(...snackbarArray.value.map((snackbar) => snackbar.originalIndex)) + 1

    const presetAttributes =
      newSnackbar.notificationType == null ? null : snackbarNotificationTypePresets[newSnackbar.notificationType]

    const newSnackbarNotification: QueuedGlobalSnackbarNotification = {
      ...newSnackbar,
      uid,
      originalIndex,
      ...presetAttributes,
      dismissable: presetAttributes?.dismissable ?? true,
    }

    if (config?.allowDuplicate === false) {
      // If the snackbar is not allowed to be duplicated,
      // we need to check if the snackbar is already in the array
      // And just return the uid of the existing snackbar if it exists
      const existingSnackbar = snackbarArray.value.find((snackbar) => {
        // Compare the snackbar without the uid and originalIndex
        const { uid: _uid1, originalIndex: _index1, timeoutId: _timeoutId1, ...snackbarWithoutId } = snackbar
        const {
          uid: _uid2,
          originalIndex: _index2,
          timeoutId: _timeoutId2,
          ...newSnackbarWithoutId
        } = newSnackbarNotification
        return isEqual(snackbarWithoutId, newSnackbarWithoutId)
      })

      if (existingSnackbar != null) {
        // Refresh the timeout for the existing snackbar
        if (existingSnackbar.timeoutId != null) {
          window.clearTimeout(existingSnackbar.timeoutId)
          existingSnackbar.timeoutId = window.setTimeout(() => {
            snackbarArray.value = snackbarArray.value.filter((snackbar) => snackbar.uid !== existingSnackbar.uid)
          }, newSnackbarNotification.timeout ?? DEFAULT_TIMEOUT)
        }
        return existingSnackbar.uid
      }
    }

    snackbarArray.value = [...snackbarArray.value, newSnackbarNotification]

    // if user prefers to persist snackbars, return immediately
    const user = computed(() => currentUser)
    const userAccessibilityInAppNotificationsPersisted =
      isAppUsing('autoDismissAppMessagesSetting') && !useAccessibilitySettings(user).isAppMessagesAutoDismissed.value

    if (userAccessibilityInAppNotificationsPersisted || newSnackbar.persist === true) {
      return uid
    }

    newSnackbarNotification.timeoutId = window.setTimeout(() => {
      snackbarArray.value = snackbarArray.value.filter((snackbar) => snackbar.uid !== uid)
    }, newSnackbar.timeout ?? DEFAULT_TIMEOUT)

    return uid
  }

  function updateSnackbar(snackbarToUpdateUid: string, newSnackbar: GlobalSnackbarNotification): void {
    const currentSnackbar = snackbarArray.value.find((snackbar) => snackbar.uid === snackbarToUpdateUid)
    if (currentSnackbar == null) return

    snackbarArray.value = snackbarArray.value.map((snackbar) =>
      snackbar.uid === snackbarToUpdateUid ? { ...currentSnackbar, ...newSnackbar } : snackbar,
    )

    // Snackbar timeout logic
    // If current snackbar has a timeouId, clear it. Else do nothing.
    // If the new snackbar is not persistent, set a new timeout.
    if (currentSnackbar.timeoutId != null) {
      window.clearTimeout(currentSnackbar.timeoutId)
    }

    if (newSnackbar.persist !== true) {
      currentSnackbar.timeoutId = window.setTimeout(() => {
        snackbarArray.value = snackbarArray.value.filter((snackbar) => snackbar.uid !== snackbarToUpdateUid)
      }, newSnackbar.timeout ?? DEFAULT_TIMEOUT)
    }
  }

  function removeSnackbar(snackbarToRemoveUid: string): void {
    snackbarArray.value = snackbarArray.value.filter((snackbar) => snackbar.uid !== snackbarToRemoveUid)
  }

  function executeActions(actions: Function[] = [], event: Event, uid: string): void {
    actions.forEach((fn): void => {
      if (typeof fn === 'function') {
        fn(event, uid)
      }
    })
  }

  function removeAllSnackbars(): void {
    snackbarArray.value = []
  }

  function genericFetchError(): void {
    setSnackbar({
      message: __('Oops! Something went wrong. Please try again.'),
      notificationType: SnackbarNotificationType.error,
    })
  }

  function isMessageBeingShown(message): boolean {
    return snackbarArray.value.some((snackbar) => snackbar.message === message)
  }

  return {
    snackbarArray,
    setSnackbar,
    updateSnackbar,
    removeSnackbar,
    removeAllSnackbars,
    executeActions,
    genericFetchError,
    isMessageBeingShown,
  }
})

export { SnackbarNotificationType }
export type { QueuedGlobalSnackbarNotification }
